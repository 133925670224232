jQuery(function($) {
    "use strict";

    //scroll to appear
    $(window).on('scroll', function() {
        var btnScrollTop = $('.to-top')
        // hide burgerBtn of header
        if ($(this).scrollTop() > 28) {
            $('.burgerBtn').removeClass('active')
        } else {
            $('.burgerBtn').addClass('active')
        }

        if ($(this).scrollTop() > 500) {
            btnScrollTop.fadeIn('slow');
            btnScrollTop.removeClass('animate__fadeOutUp');
            btnScrollTop.addClass('animate__fadeInUp');
        } else {
            btnScrollTop.fadeOut('slow');
            btnScrollTop.removeClass('animate__fadeInUp');
            btnScrollTop.addClass('animate__fadeOutUp');
        }
    });

    // mostramos el btnBurger a primera instancia
    if($(window).scrollTop() == 0) {
        $('.burgerBtn').addClass('active')
    }

    //Click event to scroll to top
    $(document).on('click', '.to-top', function() {
        $('html, body').animate({
            scrollTop: 0
        }, 800);
        return false;
    });

    // enable bootstrap tooltips
    $('[data-toggle="tooltip"]').tooltip()

    // call function of fullscreen menu
    fullScreenMenu()

    /**
     * change language
     * 
     */
    $('.change-language').on('click', function(event) {
        event.preventDefault();
        console.log('here')
        var data = $(this).data()
        $.ajax({
            url: "/language",
            method: 'get',
            data : data,
            success: function(response) {
                if (response.redirect) {
                    window.location = response.new_route;
                }
            }
        })
    })

    /**
     * change currency
     *  
     */
    $('.change-currency').on('click', function (event) {
        event.preventDefault()
        var data = $(this).data()
        $.ajax({
            url: "/currency",
            method: 'get',
            data : data,
            success: function(response) {
                location.reload()
            }
        })
    })

    $('.nav-item-locations:not(.mobile), .nav-item-vehicles:not(.mobile)').on('click', function(ev) {
        ev.preventDefault()
        location.href = $(this).prop('href')
    })

    /**
     * load info footer
     * 
     */
    var args = {url: '/component/footer-location-branches', async: true}
    $.ajaxRequest(args).then(HTML => {
        $('.social-media .footer-links').prepend(HTML.html)
    })

    $('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
        if (!$(this).next().hasClass('show')) {
          $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
        }
        var $subMenu = $(this).next(".dropdown-menu");
        $subMenu.toggleClass('show');
      
      
        $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
          $('.dropdown-submenu .show').removeClass("show");
        });
      
      
        return false;
      });
});

/** fullscreen menu in responsive */
function fullScreenMenu() {
    var burgerBtn  = $('.burgerBtn')
    var appContent = $('.app-content')
    var header     = $('header')
    var navigationOptions = $('#navigation-list .nav-link')

    // add class effect for options of navigation-list
    for (let i = 0; i <= navigationOptions.length; i++) {
        $(navigationOptions).eq(i).addClass('navlink-animation-' + i)
    }

    burgerBtn.on('click', function() {
        appContent.toggleClass('sidenavbar-active');
        header.toggleClass('sidenavbar-active');
    })
}

/** Equivalent to PHP function number_format in jquery/javascript */
function number_format (number, decimals, dec_point, thousands_sep) {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

function chunk(array, size) {
    const chunked_arr = [];
    for (let i = 0; i < array.length; i++) {
      const last = chunked_arr[chunked_arr.length - 1];
      if (!last || last.length === size) {
        chunked_arr.push([array[i]]);
      } else {
        last.push(array[i]);
      }
    }
    return chunked_arr;
}

/** AjaxHelpers */
$.ajaxRequest = function (args) {
    return new Promise((resolve, reject) => {
        $.ajax(args).done(resolve).fail(reject)
    })
}


function cars_list(prefix, url, id, provider_id) {
    event.preventDefault();
    d = new Date();
    d.setDate(d.getDate() + 1);

    d2 = new Date();
    d2.setDate(d2.getDate() + 2);
    pickupD = d.toISOString("en").slice(0, 10);
    dropoffD = d2.toISOString("en").slice(0, 10);

    $url =
        `pickup_date=${pickupD}&dropoff_date=${dropoffD}&language=${prefix}&urlpost=${url}&type_car=0&provider_id=${provider_id}&pickup=${id}&pickup_hour=11:00&dropoff_hour=11:00`;
    lang = (prefix == 'es') ? '/es' : '';

    promise = new Promise((resolve, reject) => {
        $.ajax({
            url: '/booking',
            method: 'POST',
            headers: {"X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")},
            dataType: 'json',
            data: $url,
            async: true
        }).done(resolve).fail(function(error) {
            Swal.fire(
                error.status + ' - Error',
                error.responseJSON.error,
                'error'
            );
            console.log(error)
        });
    }).then(response => {
        window.location = lang + decodeURIComponent(url);
    });

}

$(document).delegate("#btn_click", "click", function(event){
    event.preventDefault();
    var vehicle_id = $(this).data('vehicle_id'),
        url = $(this).data('url'),
        transmition = $(this).data('transmission');

        $url_trans = (transmition+"").toLowerCase().replaceAll(" ","_");
        args = {url: '/component/create/sesion/vicle-id/' + vehicle_id, async: false,data:{"_transmission":$url_trans}}
    $.ajaxRequest(args).then(res => {
        (res.status == "success" ? location.href = url : '');
    }).catch(err => {
        Swal.fire('Ups!', 'Something went wrong! ' +  err.responseJSON.message, 'error')
        console.log(err)
    }).finally(() => {  })
})